import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import '@/assets/css/reset.css'
import '@/assets/fontIcon/iconfont.css'
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

Vue.filter('dateFormat', function(originVal){
  const dt = new Date(originVal)
 
  const y = dt.getFullYear()
  // padStart 填充到多少位，填充内容为'0'
  const m = (dt.getMonth() + 1 + '').padStart(2, '0');
  const d = (dt.getDate() + '').padStart(2, '0');
 
  return `${y}-${m}-${d}`
})

Vue.filter('dateTimeFormat', function(originVal){
  const dt = new Date(originVal)
 
  const y = dt.getFullYear()
  // padStart 填充到多少位，填充内容为'0'
  const m = (dt.getMonth() + 1 + '').padStart(2, '0');
  const d = (dt.getDate() + '').padStart(2, '0');
 
  const hh = (dt.getHours() + '').padStart(2, '0');
  const mm = (dt.getMinutes() + '').padStart(2, '0');
  const ss = (dt.getSeconds() + '').padStart(2, '0');
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() { //新增方法
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')