import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/template',
    name: '模板',
    component: () => import('../views/template/template.vue')
  },
  {
    path: '/set',
    name: '设置',
    component: () => import('../views/set/set.vue'),
  },
  {
    path: '/invalidAnswer',
    name: '无效答卷',
    component: () => import('../views/set/invalidAnswer.vue'),
  },
  {
    path: '/share',
    name: '分享',
    component: () => import('../views/share/share.vue')
  },
  {
    path: '/questionTypes',
    name: '题型',
    // meta: {
    //   title: '登录',
    //   content:{
    //     keywords:'111',
    //     description:'eeee'
    //   }
    // },
    component: () => import('../views/form/questionTypes/questionTypes.vue')
  },
  {
    path: '/questionBank',
    name: '题库',
    component: () => import('../views/form/questionBank/questionBank.vue')
  },
  {
    path: '/questionBankSet',
    name: '题库设置',
    component: () => import('../views/form/questionBank/questionBankSet.vue')
  },
  {
    path: '/style',
    name: '风格',
    component: () => import('../views/form/style.vue')
  },
  {
    path: '/preview',
    name: '预览',
    component: () => import('../views/form/preview/preview.vue')
  },
  {
    path: '/previewPC',
    name: 'PC预览',
    component: () => import('../views/form/preview/previewPC.vue')
  },
  {
    path: '/previewMobile',
    name: '手机预览',
    component: () => import('../views/form/preview/previewMobile.vue')
  },
  {
    path: '/detail',
    name: '详情',
    component: () => import('../views/form/detail/detail.vue')
  },
  {
    path: '/ques_questionTypes',
    name: '题型',
    component: () => import('../views/ques/questionTypes.vue')
  },
  {
    path: '/ques_questions',
    name: '题库',
    component: () => import('../views/ques/questions.vue')
  },
  {
    path: '/ques_style',
    name: '风格',
    component: () => import('../views/ques/style.vue')
  },
  {
    path: '/ques_preview',
    name: '预览',
    component: () => import('../views/ques/preview.vue')
  },
  {
    path: '/ques_previewPC',
    name: 'PC预览',
    component: () => import('../views/ques/previewPC.vue')
  },
  {
    path: '/ques_previewMobile',
    name: '手机预览',
    component: () => import('../views/ques/previewMobile.vue')
  },
  {
    path: '/ques_detail',
    name: '详情',
    component: () => import('../views/ques/detail.vue')
  },
  {
    path: '/exam_questionTypes',
    name: '题型',
    component: () => import('../views/exam/questionTypes.vue')
  },
  {
    path: '/exam_questions',
    name: '题库',
    component: () => import('../views/exam/questions.vue')
  },
  {
    path: '/exam_style',
    name: '风格',
    component: () => import('../views/exam/style.vue')
  },
  {
    path: '/exam_preview',
    name: '预览',
    component: () => import('../views/exam/preview.vue')
  },
  {
    path: '/exam_previewPC',
    name: 'PC预览',
    component: () => import('../views/exam/previewPC.vue')
  },
  {
    path: '/exam_previewMobile',
    name: '手机预览',
    component: () => import('../views/exam/previewMobile.vue')
  },
  {
    path: '/exam_detail',
    name: '详情',
    component: () => import('../views/exam/detail.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('../views/login/register.vue')
  },
  {
    path: '/',
    name: '创建表单',
    component: () => import('../views/ucenter/create/list.vue')
  },
  {
    path: '/create',
    name: '创建表单',
    component: () => import('../views/ucenter/create/list.vue')
  },
  {
    path: '/myForm',
    name: '我的表单',
    component: () => import('../views/ucenter/myForm/list.vue')
  },
  {
    path: '/model',
    name: '模板中心',
    component: () => import('../views/ucenter/template/list.vue')
  },
  {
    path: '/collect',
    name: '我的收藏',
    component: () => import('../views/ucenter/collect/list.vue')
  },
  {
    path: '/answer',
    name: '我的答题',
    component: () => import('../views/ucenter/answer/list.vue')
  },
  {
    path: '/emailSet',
    name: '邮箱设置',
    component: () => import('../views/ucenter/emailSet/edit.vue')
  },
  {
    path: '/linkman',
    name: '联系人',
    component: () => import('../views/ucenter/linkman/list.vue')
  },
  {
    path: '/linkmanGroup',
    name: '联系人组',
    component: () => import('../views/ucenter/linkman/groupList.vue')
  },
  {
    path: '/linkmanEdit',
    name: '联系人',
    component: () => import('../views/ucenter/linkman/edit.vue')
  },
  {
    path: '/prize',
    name: '我的奖品',
    component: () => import('../views/ucenter/prize/list.vue')
  },
  {
    path: '/formData',
    name: '表单数据',
    component: () => import('../views/ucenter/statics/formList.vue')
  },
  {
    path: '/quesData',
    name: '问卷数据',
    component: () => import('../views/ucenter/statics/quesList.vue')
  },
  {
    path: '/examData',
    name: '测评数据',
    component: () => import('../views/ucenter/statics/examList.vue')
  },
  {
    path: '/statics',
    name: '数据',
    component: () => import('../views/ucenter/statics/list.vue')
  },
  {
    path: '/user',
    name: '用户管理',
    component: () => import('../views/ucenter/user/list.vue')
  },
  {
    path: '/userEdit',
    name: '用户管理',
    component: () => import('../views/ucenter/user/edit.vue')
  },
  {
    path: '/notice',
    name: '消息中心',
    component: () => import('../views/ucenter/notice/list.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || ''; // 从路由配置中获取标题信息并赋值给document.title
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
        existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
        existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
        contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
    }
  }
  next()
})
export default router